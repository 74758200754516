import React from 'react';
import { hostname } from '../../utils/constants';
import SiteLayout from '../../components/layout/SiteLayout';
import { Link } from 'gatsby';
import TeamMember from '../../components/team-member';
import loadable from '@loadable/component';
const RoContact = loadable(() => import('../../templates/homepage/ro-contact'));


const Team = ({ data }) => {
  let ldJsonHome = "Inicio";
  let ldJsonLevel1Route = "/equipo/";
  let ldJsonLevel1Name = "Equipo";

  let navigationLdJson = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    "itemListElement": [
      {
        "@type": "ListItem",
        "position": 1,
        "item": {
          "@id": hostname + "/",
          "name": ldJsonHome
        }
      },
      {
        "@type": "ListItem",
        "position": 2,
        "item": {
          "@id": hostname + ldJsonLevel1Route,
          "name": ldJsonLevel1Name
        }
      }
    ]
  };

  return (
    <SiteLayout relatedPages={data?.mdx?.fields?.relatedPages} lang={data?.mdx?.fields?.lang} canonical={data?.mdx?.fields?.canonical} title={data?.mdx?.frontmatter?.title} seo={{metaTitle: data?.mdx?.frontmatter?.metaTitle, metaDescription: data?.mdx?.frontmatter?.metaDescription, metaImage: data?.mdx?.frontmatter?.metaImage?.publicURL, metaCover: data?.mdx?.frontmatter?.metaCover?.publicURL, twitterCard: data?.mdx?.frontmatter?.twitterCard, ldJson: data?.mdx?.frontmatter?.ldJson, navigationLdJson: navigationLdJson, keywords: data?.mdx?.frontmatter?.metaKeywords, slug: data?.mdx?.fields?.slug.replace("/posts/index", "")}}>
      <div className={"w-full flex flex-col features-bg-image px-5 md:px-10 lg:px-20 xl:px-60 xxl:px-96  "}>
        <div className={"w-full"}>
          <p className={"text-right w-full text-sm text-gray-500 mt-5"}><Link to={"/"}>Inicio</Link> > Equipo</p>
        </div>
        <h1 className={"text-xl md:text-2xl lg:text-4xl text-center text-primary-default font-light py-20"}>{data?.mdx?.frontmatter?.title}</h1>
      </div>
      <div className={"w-full flex flex-col px-5 md:px-10 lg:px-20 xl:px-40 py-10"}>
        <h2 className={"text-lg md:text-xl lg:text-2xl text-primary-default font-light text-center"}>{data?.mdx?.frontmatter?.subtitle}</h2>
        {data?.mdx?.frontmatter?.descriptionTexts.map((description, index) => <p key={index} className={"py-10 lg:text-lg text-gray-500 indented text-justify"}>{description}</p>)}
      </div>
      <div className={"w-full flex flex-col md:flex-row flex-wrap px-10 md:px-10 lg:px-20 xl:px-40 py-10 items-center"}>
        {data?.mdx?.exports?.team.map((member, index) => (
          <TeamMember key={index} index={index} member={member} />
        ))}
      </div>

      <RoContact />
    </SiteLayout>
  );
};

export default Team;
